<template>
  <div @click="$emit('click')">
    <v-card-title style="">
      <span style="width: 100%;font-size;14px;">{{ vessel.name }}</span>
      <i style="font-size: 12px">Position: {{ vessel.getPositionDisplay() }}</i>
    </v-card-title>
  </div>
</template>
<script>
import { Vessel } from "./mapHelpers";

export default {
  name: "map-fleet-vessel",
  props: {
    vessel: { type: Vessel, default: () => {} },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.legend-wrapper {
  max-width: 300px;
}
.settings-button {
  position: absolute;
  left: 5px;
  bottom: 5px;
  z-index: 9999;
}
.legend-icon {
  margin-top: 5px;
}
</style>
