const state = () => ({
    navVisible: true,
    navExpanded: false,
});
  
const getters = {
	navVisible: (state) => state.navVisible,
    navExpanded: (state) => state.navExpanded,
};

const actions = {};

const mutations = {
    setNavVisibility(state, val) {
        state.navVisible = val;
    },
    setNavExpanded(state, val) {
        state.navExpanded = val;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};