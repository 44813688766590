<script>
import dataTable from "../common/dataTable.vue";
export default {
  components: { dataTable },
  name: "user-table",
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Email", value: "emailAddress", sortable: false },
        { text: "Company", value: "companyName", sortable: false },
        { text: "Last Login", value: "lastLogin", sortable: false },
      ],
      defaultObject: {
        id: null,
        name: null,
        companyName: null,
        companyId: null,
        companyType: "",
      },
    };
  },
  computed: {
    companyDataSource() {
      return this.$store.getters.companiesDataSource;
    },
  },
};
</script>
<template>
  <data-table
    title="Users"
    objectName="User"
    :headers="headers"
    getMethod="getUsers"
    updateMethod="updateUser"
    deleteMethod="deleteUser"
    :editable="true"
    :defaultObject="defaultObject"
  >
    <template v-slot:editForm="{ item }">
      <v-text-field class="mb-2" outlined hide-details="auto" v-model="item.name" dense label="Name"></v-text-field>
      <v-text-field class="mb-2" outlined hide-details="auto" v-model="item.emailAddress" dense label="Name"></v-text-field>
      <v-autocomplete
        class="mb-2"
        outlined
        sea
        item-text="value"
        item-value="key"
        hide-details="auto"
        :items="companyDataSource"
        v-model="item.companyId"
        dense
        label="Company"
      ></v-autocomplete>
    </template>
  </data-table>
</template>
