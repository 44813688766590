<script>
import { toDateTime } from "./../../helpers/dateHelpers";
export default {
  name: "vessel-information-card",
  props: {
    item: { type: Object, default: null },
    isVessel: { type: Boolean, default: false },
    trailDuration: { type: Number },
  },
  data() {
    return {};
  },
  methods: {
    toDateTime: toDateTime,
  },
  computed: {
    trailDurationLocal: {
      get() {
        return this.trailDuration;
      },
      set(value) {
        this.$emit("update:trailDuration", value);
      },
    },
  },
};
</script>
<template>
  <v-card elevation="2">
    <v-card-text>
      <h4 style="text-align: left">{{ item.name }}</h4>
      <v-simple-table dense class="data-table" v-if="isVessel">
        <tbody>
          <tr>
            <td>Position</td>
            <td>{{ item.getPositionDisplay() }}</td>
          </tr>
          <tr>
            <td>Heading</td>
            <td>{{ item.heading }}</td>
          </tr>
          <tr>
            <td>Destination</td>
            <td>{{ item.destination ? item.destination : "-" }}</td>
          </tr>
          <tr>
            <td>Speed</td>
            <td>{{ `${item.speed} knots` }}</td>
          </tr>
          <tr>
            <td>MMSI</td>
            <td>{{ item.mmsi }}</td>
          </tr>
          <tr>
            <td>IMO</td>
            <td>{{ item.imo }}</td>
          </tr>
          <tr>
            <td>Vessel Type</td>
            <td>{{ item.type }}</td>
          </tr>
          <tr>
            <td>Last Updated</td>
            <td>{{ toDateTime(item.lastUpdated) }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table dense class="data-table" v-else>
        <tbody>
          <tr>
            <td>Position</td>
            <td>{{ item.getPositionDisplay() }}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{{ item.description }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-if="isVessel">
        <label style="float: left">Vessel History Trail (Hours)</label>
        <v-btn-toggle class="d-flex" style="width: 100%" v-model="trailDurationLocal">
          <v-btn small :value="1" style="width: 25%"> 1 </v-btn>

          <v-btn small :value="6" style="width: 25%"> 6 </v-btn>

          <v-btn small :value="12" style="width: 25%"> 12 </v-btn>

          <v-btn small :value="24" style="width: 25%"> 24 </v-btn>
        </v-btn-toggle>
      </div>
    </v-card-text>
  </v-card>
</template>
<style scoped>
.data-table tr > td:first-child {
  text-align: right;
}
.data-table tr > td:last-child {
  text-align: left;
}
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px !important;
}
</style>
