import { postRequest, getRequest } from "../../../requests/requests";

const state = () => ({
    companiesDataSource: [],
});

const getters = {
    companiesDataSource: (state) => state.companiesDataSource,
};

const actions = {
    async getCompanies(state, params) {
        var response = await getRequest('Company', 'Get', params);
        return response.data
    },
    async updateCompany(state, params) {
        return await postRequest('Company', 'AddOrUpdate', params);
    },
    async deleteCompany(state, params) {
        return await postRequest('Company', `Delete/${params.id}`);
    },
    async getCompaniesDataSource() {
        var response = await getRequest('Company', `GetDataSource`);
        return response.data;
    },
};

const mutations = {
    setCompaniesDataSource(state, val) {
        state.companiesDataSource = val;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};