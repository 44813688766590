function getPath(points) {
  var latlngs = [];

  for (var i = 0; i < points.length; i++) {
    latlngs.push([points[i].lat, points[i].lng]);
  }
  return latlngs;
}
class MarkerBase {
  constructor(name, lat, lng, permanentLabel) {
    this.name = name;
    this.lat = lat;
    this.lng = lng;
    this.permanentLabel = permanentLabel;
  }

  getPosition() {
    return [this.lat, this.lng];
  }

  getPositionDisplay() {
    return `${this.lat}, ${this.lng}`;
  }
}

class Vessel extends MarkerBase {
  constructor(name, lat, lng, permanentLabel, imo, mmsi, heading, destination, speed, type, lastUpdated) {
    super(name, lat, lng);
    this.name = name;
    this.lat = lat;
    this.lng = lng;
    this.permanentLabel = permanentLabel;
    this.imo = imo;
    this.mmsi = mmsi;
    this.heading = heading;
    this.destination = destination;
    this.speed = speed;
    this.length = type == "JackUp" ? 12 : 9;
    this.width = type == "JackUp" ? 12 : 6;
    this.type = type;
    this.trail = null;
    this.lastUpdated = new Date(lastUpdated);

    /* 
        if (!(this.lastUpdated instanceof Date) || isNaN(this.lastUpdated)) {
          throw "Invalid date!!";
        } */
  }

  getMarkerIcon(L) {
    if (!this.type) {
      return;
    }
    return L.divIcon({
      iconAnchor: [this.length, this.width],
      iconSize: [this.length * 2, this.width * 2],
      className: this.type == "JackUp" ? "JackUp" : "arrow" + " " + this.type.replace(/ /g, ""),
    });
  }

  setTrail(path) {
    this.trail = path;
  }

  createMarker(L, onClick) {
    var position = this.getPosition();
    var marker = L.rotatedMarker(position, { icon: this.getMarkerIcon(L), rotationAngle: this.heading - 90, name: this.name, imo: this.imo });
    marker.bindTooltip(this.name, { permanent: this.permanentLabel, className: "label", offset: [this.length, -this.width], direction: "right" });
    marker.on("click", function (e) {
      onClick(e.target.options.imo);
    });
    return marker;
  }
}

class StaticStructure extends MarkerBase {
  constructor(name, lat, lng, permanentLabel, description) {
    super(name, lat, lng);
    this.name = name;
    this.lat = lat;
    this.lng = lng;
    this.permanentLabel = permanentLabel;
    this.length = 9;
    this.width = 9;
    this.description = description;
  }

  getMarkerIcon(L) {
    return L.divIcon({
      iconAnchor: [this.length, this.width],
      iconSize: [this.length * 2, this.width * 2],
      className: "structure-legend",
    });
  }

  createMarker(L, onClick) {
    var position = this.getPosition();
    var marker = new L.Marker(position, { icon: this.getMarkerIcon(L), name: this.name });
    marker.bindTooltip(this.name, { permanent: false, className: "label", offset: [this.length, -this.width], direction: "right" });
    marker.on("click", function (e) {
      onClick(e);
    });
    return marker;
  }
}

class Filterable {
  constructor(name, lat, lng, type, imo) {
    this.name = name;
    this.lat = lat;
    this.lng = lng;
    this.type = type;
    this.imo = imo;
  }
  getPositionDisplay() {
    return `${this.lat}, ${this.lng}`;
  }
}
const ports = [
  { name: "Houston", lat: 29.6289787, lng: -95.0173063 },
  { name: "Freeport", lat: 28.9714022, lng: -95.3382014 },
  { name: "New Orleans", lat: 29.8969541, lng: -90.0770147 },
  { name: "Pensacola", lat: 30.4026868, lng: -87.2150943 },
  { name: "Port Arthur", lat: 29.9497177, lng: -94.0893323 },
  { name: "Baton Rouge", lat: 30.4464298, lng: -91.1966192 },
  { name: "Aberdeen", lat: 57.143, lng: -2.079 },
  { name: "Aith", lat: 60.286, lng: -1.374 },
  { name: "Appledore", lat: 51.049, lng: -4.191 },
  { name: "Ardglass", lat: 54.261, lng: -5.605 },
  { name: "Ardrossan", lat: 55.641, lng: -4.821 },
  { name: "Avonmouth", lat: 51.504, lng: -2.708 },
  { name: "Baltasound", lat: 60.76, lng: -0.838 },
  { name: "Barrow-In-Furness", lat: 54.1, lng: -3.216 },
  { name: "Belfast", lat: 54.619, lng: -5.898 },
  { name: "Belmont", lat: 60.683, lng: -0.969 },
  { name: "Blyth", lat: 55.13, lng: -1.505 },
  { name: "Boston", lat: 52.969, lng: -0.018 },
  { name: "Braefoot", lat: 56.036, lng: -3.311 },
  { name: "Brighton Marina", lat: 50.811, lng: -0.1 },
  { name: "Brighton Pier", lat: 50.817, lng: -0.135 },
  { name: "Brixham", lat: 50.401, lng: -3.509 },
  { name: "Brodick", lat: 55.577, lng: -5.138 },
  { name: "Buckie", lat: 57.681, lng: -2.957 },
  { name: "Burnham On Crouch", lat: 51.621, lng: 0.822 },
  { name: "Burntisland", lat: 56.056, lng: -3.234 },
  { name: "Cairnryan", lat: 54.968, lng: -5.021 },
  { name: "Campbeltown", lat: 55.426, lng: -5.6 },
  { name: "Canvey Island", lat: 51.512, lng: 0.557 },
  { name: "Chatham", lat: 51.402, lng: 0.543 },
  { name: "Cliffe", lat: 51.463, lng: 0.455 },
  { name: "Coverpack", lat: 50.023, lng: -5.094 },
  { name: "Cowes", lat: 50.757, lng: -1.293 },
  { name: "Cullivoe", lat: 60.7, lng: -1.001 },
  { name: "Dagenham", lat: 51.515, lng: 0.144 },
  { name: "Dartmouth", lat: 50.357, lng: -3.577 },
  { name: "Dover", lat: 51.123, lng: 1.33 },
  { name: "Dunball", lat: 51.162, lng: -2.991 },
  { name: "Dundee", lat: 56.463, lng: -2.947 },
  { name: "Dunoon", lat: 55.946, lng: -4.921 },
  { name: "Eastbourne", lat: 50.794, lng: 0.328 },
  { name: "Eastham", lat: 53.323, lng: -2.949 },
  { name: "Ellesmere", lat: 53.291, lng: -2.897 },
  { name: "Erith", lat: 51.493, lng: 0.168 },
  { name: "Exmouth", lat: 50.615, lng: -3.419 },
  { name: "Fair Isle", lat: 59.539, lng: -1.604 },
  { name: "Falmouth", lat: 50.161, lng: -5.073 },
  { name: "Faslane", lat: 56.06, lng: -4.821 },
  { name: "Fawley", lat: 50.838, lng: -1.329 },
  { name: "Felixstowe", lat: 51.959, lng: 1.298 },
  { name: "Finnart", lat: 56.114, lng: -4.836 },
  { name: "Fishguard", lat: 52.005, lng: -4.981 },
  { name: "Fleetwood", lat: 53.922, lng: -3.008 },
  { name: "Flotta", lat: 58.84, lng: -3.125 },
  { name: "Fowey", lat: 50.336, lng: -4.631 },
  { name: "Fraserburgh", lat: 57.693, lng: -2 },
  { name: "Garston", lat: 53.349, lng: -2.905 },
  { name: "Glasgow", lat: 55.87, lng: -4.336 },
  { name: "Glen Mallan", lat: 56.13, lng: -4.818 },
  { name: "Goole", lat: 53.699, lng: -0.879 },
  { name: "Gosport", lat: 50.801, lng: -1.127 },
  { name: "Gourock", lat: 55.96, lng: -4.812 },
  { name: "Grangemouth", lat: 56.029, lng: -3.705 },
  { name: "Granton", lat: 55.984, lng: -3.223 },
  { name: "Gravesend", lat: 51.445, lng: 0.387 },
  { name: "Great Yarmouth", lat: 52.592, lng: 1.726 },
  { name: "Greenock", lat: 55.957, lng: -4.762 },
  { name: "Grimsby", lat: 53.581, lng: -0.074 },
  { name: "Gunness", lat: 53.601, lng: -0.72 },
  { name: "Hamars Ness", lat: 60.63, lng: -0.931 },
  { name: "Hartlepool", lat: 54.694, lng: -1.194 },
  { name: "Harwich", lat: 51.945, lng: 1.28 },
  { name: "Holyhead", lat: 53.318, lng: -4.629 },
  { name: "Houton", lat: 58.917, lng: -3.184 },
  { name: "Hugh Town", lat: 49.917, lng: -6.317 },
  { name: "Hull", lat: 53.742, lng: -0.28 },
  { name: "Hunters Quay", lat: 55.971, lng: -4.909 },
  { name: "Hunterston", lat: 55.752, lng: -4.872 },
  { name: "Immingham", lat: 53.636, lng: -0.185 },
  { name: "Invergordon", lat: 57.687, lng: -4.161 },
  { name: "Inverness", lat: 57.484, lng: -4.244 },
  { name: "Ipswich", lat: 52.04, lng: 1.158 },
  { name: "Isles Of Scilly", lat: 49.92, lng: -6.318 },
  { name: "Itchen", lat: 50.9, lng: -1.383 },
  { name: "James Watt Dock", lat: 55.945, lng: -4.732 },
  { name: "Kilchoan", lat: 56.688, lng: -6.094 },
  { name: "Kilkeel", lat: 54.059, lng: -5.995 },
  { name: "Kings Lynn", lat: 52.761, lng: 0.394 },
  { name: "Kirkwall", lat: 58.987, lng: -2.959 },
  { name: "Larne", lat: 54.846, lng: -5.793 },
  { name: "Leith", lat: 55.982, lng: -3.171 },
  { name: "Lerwick", lat: 60.168, lng: -1.152 },
  { name: "Littlehampton", lat: 50.807, lng: -0.548 },
  { name: "Liverpool", lat: 53.419, lng: -3.008 },
  { name: "Lochranza", lat: 55.708, lng: -5.3 },
  { name: "London", lat: 51.497, lng: -0.05 },
  { name: "Londonderry", lat: 55.045, lng: -7.254 },
  { name: "Longhope", lat: 58.801, lng: -3.206 },
  { name: "Lowestoft", lat: 52.474, lng: 1.736 },
  { name: "Lymington", lat: 50.754, lng: -1.528 },
  { name: "Mcinroy", lat: 55.952, lng: -4.853 },
  { name: "Mid Yell", lat: 60.598, lng: -1.059 },
  { name: "Milford Haven", lat: 51.7, lng: -5.003 },
  { name: "Mistley", lat: 51.945, lng: 1.086 },
  { name: "Moaness", lat: 58.916, lng: -3.312 },
  { name: "Montrose", lat: 56.704, lng: -2.466 },
  { name: "Mylor", lat: 50.177, lng: -5.052 },
  { name: "New Holland", lat: 53.707, lng: -0.362 },
  { name: "Newhaven", lat: 50.786, lng: 0.058 },
  { name: "Newlyn", lat: 50.104, lng: -5.547 },
  { name: "Newport", lat: 51.56, lng: -2.984 },
  { name: "Newquay", lat: 50.419, lng: -5.083 },
  { name: "Nigg", lat: 57.694, lng: -4.032 },
  { name: "Padstow", lat: 50.54, lng: -4.936 },
  { name: "Perranporth", lat: 50.347, lng: -5.155 },
  { name: "Peterhead", lat: 57.498, lng: -1.782 },
  { name: "Plymouth", lat: 50.372, lng: -4.15 },
  { name: "Poole", lat: 50.711, lng: -1.989 },
  { name: "Port Isaac", lat: 50.593, lng: -4.833 },
  { name: "Port Talbot", lat: 51.579, lng: -3.798 },
  { name: "Portavogie", lat: 54.458, lng: -5.437 },
  { name: "Portbury", lat: 51.497, lng: -2.722 },
  { name: "Porthleven", lat: 50.086, lng: -5.315 },
  { name: "Portland Uk", lat: 50.584, lng: -2.443 },
  { name: "Portreath", lat: 50.263, lng: -5.291 },
  { name: "Portsmouth", lat: 50.803, lng: -1.102 },
  { name: "Purfleet", lat: 51.468, lng: 0.255 },
  { name: "Ramsgate", lat: 51.329, lng: 1.419 },
  { name: "Ridham Dock", lat: 51.416, lng: 0.735 },
  { name: "Rosyth", lat: 56.022, lng: -3.448 },
  { name: "Rothesay", lat: 55.839, lng: -5.055 },
  { name: "Saint Peter Port", lat: 49.45, lng: -2.533 },
  { name: "Salcombe", lat: 50.239, lng: -3.761 },
  { name: "Scalloway", lat: 60.135, lng: -1.277 },
  { name: "Scarborough", lat: 54.283, lng: -0.39 },
  { name: "Scrabster", lat: 58.611, lng: -3.544 },
  { name: "Seaham", lat: 54.836, lng: -1.326 },
  { name: "Sharpness", lat: 51.722, lng: -2.476 },
  { name: "Sheerness", lat: 51.443, lng: 0.742 },
  { name: "Shoreham", lat: 50.83, lng: -0.233 },
  { name: "Solent", lat: 50.843, lng: -1.107 },
  { name: "South Stifford", lat: 51.47, lng: 0.294 },
  { name: "Southampton", lat: 50.898, lng: -1.421 },
  { name: "Southwold", lat: 52.33, lng: 1.686 },
  { name: "St Margaret'S Hope", lat: 58.832, lng: -2.961 },
  { name: "St.Ives", lat: 50.21, lng: -5.476 },
  { name: "Stanlow", lat: 53.287, lng: -2.861 },
  { name: "Stornoway", lat: 58.207, lng: -6.384 },
  { name: "Stranraer", lat: 54.908, lng: -5.027 },
  { name: "Stromness", lat: 58.963, lng: -3.295 },
  { name: "Sullom Voe", lat: 60.453, lng: -1.286 },
  { name: "Sunderland", lat: 54.911, lng: -1.381 },
  { name: "Sutton Bridge", lat: 52.772, lng: 0.198 },
  { name: "Swansea", lat: 51.614, lng: -3.921 },
  { name: "Symbister", lat: 60.343, lng: -1.028 },
  { name: "Tees", lat: 54.61, lng: -1.172 },
  { name: "Teignmouth", lat: 50.544, lng: -3.503 },
  { name: "Tenby Harbour", lat: 51.673, lng: -4.695 },
  { name: "Thames", lat: 51.507, lng: 0.508 },
  { name: "Thamesport", lat: 51.434, lng: 0.694 },
  { name: "Tilbury", lat: 51.461, lng: 0.346 },
  { name: "Tobermory", lat: 56.621, lng: -6.066 },
  { name: "Toft", lat: 60.465, lng: -1.201 },
  { name: "Torpoint", lat: 50.375, lng: -4.193 },
  { name: "Troon", lat: 55.548, lng: -4.68 },
  { name: "Tyne", lat: 54.999, lng: -1.479 },
  { name: "Uyeasound", lat: 60.688, lng: -0.917 },
  { name: "Walls", lat: 60.226, lng: -1.565 },
  { name: "Warrenpoint", lat: 54.1, lng: -6.26 },
  { name: "Wemyss Bay", lat: 55.876, lng: -4.892 },
  { name: "Weymouth", lat: 50.611, lng: -2.454 },
  { name: "White Hill Point", lat: 54.983, lng: -1.45 },
  { name: "Whitehaven", lat: 54.551, lng: -3.594 },
  { name: "Whitstable", lat: 51.364, lng: 1.026 },
  { name: "Workington", lat: 54.65, lng: -3.568 },
  { name: "Yarmouth", lat: 50.707, lng: -1.5 },
  { name: "Aagotnes", lat: 60.417, lng: 5.011 },
  { name: "Aaheim", lat: 62.04, lng: 5.517 },
  { name: "Aalesund", lat: 62.47, lng: 6.137 },
  { name: "Alta", lat: 69.979, lng: 23.319 },
  { name: "Andenes", lat: 69.324, lng: 16.128 },
  { name: "Arendal", lat: 58.463, lng: 8.808 },
  { name: "Arsvagen", lat: 59.17, lng: 5.453 },
  { name: "Askoy", lat: 60.41, lng: 5.23 },
  { name: "Austevollrhella", lat: 60.106, lng: 5.177 },
  { name: "Ballstad", lat: 68.074, lng: 13.536 },
  { name: "Batsfjord", lat: 70.634, lng: 29.731 },
  { name: "Bergen", lat: 60.391, lng: 5.322 },
  { name: "Berlevag", lat: 70.86, lng: 29.102 },
  { name: "Bodoe", lat: 67.288, lng: 14.378 },
  { name: "Bognes", lat: 68.224, lng: 16.075 },
  { name: "Borgundvag", lat: 62.15, lng: 5.307 },
  { name: "Brekstad", lat: 63.688, lng: 9.675 },
  { name: "Brevik", lat: 59.061, lng: 9.7 },
  { name: "Bronnoysund", lat: 65.481, lng: 12.171 },
  { name: "Drammen", lat: 59.738, lng: 10.235 },
  { name: "Dusavik", lat: 59.001, lng: 5.665 },
  { name: "Egersund", lat: 58.453, lng: 5.991 },
  { name: "Elnesvagen", lat: 62.848, lng: 7.133 },
  { name: "Farsund", lat: 58.088, lng: 6.792 },
  { name: "Finnsnes", lat: 69.236, lng: 17.977 },
  { name: "Fiskarstranda", lat: 62.441, lng: 6.277 },
  { name: "Flekkeroy", lat: 58.079, lng: 8.005 },
  { name: "Floro", lat: 61.597, lng: 5.036 },
  { name: "Florvag", lat: 60.414, lng: 5.231 },
  { name: "Fosnavaag", lat: 62.342, lng: 5.636 },
  { name: "Fredrikstad", lat: 59.191, lng: 10.95 },
  { name: "Fredvang", lat: 68.083, lng: 13.17 },
  { name: "Frihetsholmen", lat: 63.638, lng: 8.802 },
  { name: "Gamvik", lat: 71.063, lng: 28.255 },
  { name: "Geiranger", lat: 62.103, lng: 7.193 },
  { name: "Geitanger", lat: 60.393, lng: 5.068 },
  { name: "Grimstad", lat: 58.332, lng: 8.587 },
  { name: "Gryllefjord", lat: 69.348, lng: 17.087 },
  { name: "Halden", lat: 59.117, lng: 11.386 },
  { name: "Halhjem", lat: 60.145, lng: 5.427 },
  { name: "Hammerfest", lat: 70.667, lng: 23.679 },
  { name: "Hamnvik", lat: 68.775, lng: 17.182 },
  { name: "Hanoytangen", lat: 60.445, lng: 5.101 },
  { name: "Hareid", lat: 62.371, lng: 6.046 },
  { name: "Haroysundet", lat: 62.884, lng: 6.95 },
  { name: "Harstad", lat: 68.791, lng: 16.572 },
  { name: "Hasvik", lat: 70.483, lng: 22.163 },
  { name: "Haugesund", lat: 59.418, lng: 5.258 },
  { name: "Havoysund", lat: 70.995, lng: 24.663 },
  { name: "Hellesylt", lat: 62.089, lng: 6.89 },
  { name: "Heroy", lat: 65.993, lng: 12.307 },
  { name: "Hinna", lat: 58.919, lng: 5.74 },
  { name: "Hjellestad", lat: 60.259, lng: 5.244 },
  { name: "Hjorungavag", lat: 62.351, lng: 6.076 },
  { name: "Holmestrand", lat: 59.489, lng: 10.322 },
  { name: "Honningsvaag", lat: 70.98, lng: 25.97 },
  { name: "Horneskilen", lat: 59.191, lng: 11.149 },
  { name: "Hoylandsbygda", lat: 59.791, lng: 5.79 },
  { name: "Hufthamer", lat: 60.123, lng: 5.2 },
  { name: "Husoya", lat: 59.339, lng: 5.301 },
  { name: "Hvaler", lat: 59.024, lng: 11.039 },
  { name: "Juvika", lat: 64.904, lng: 11.281 },
  { name: "Kaarsto", lat: 59.272, lng: 5.501 },
  { name: "Kabelvaag", lat: 68.21, lng: 14.483 },
  { name: "Kirkenes", lat: 69.727, lng: 30.056 },
  { name: "Kjollefjord", lat: 70.95, lng: 27.342 },
  { name: "Klokkarvik", lat: 60.224, lng: 5.157 },
  { name: "Knappskog", lat: 60.38, lng: 5.059 },
  { name: "Kragero", lat: 58.868, lng: 9.415 },
  { name: "Kristiansund", lat: 63.115, lng: 7.767 },
  { name: "Kystveien", lat: 58.117, lng: 8.107 },
  { name: "Larvik", lat: 59.043, lng: 10.034 },
  { name: "Leirvik", lat: 59.78, lng: 5.506 },
  { name: "Leka", lat: 65.088, lng: 11.74 },
  { name: "Levanger", lat: 63.748, lng: 11.294 },
  { name: "Longyearbyen", lat: 78.248, lng: 15.572 },
  { name: "Lovund", lat: 66.367, lng: 12.371 },
  { name: "Lysaker", lat: 59.911, lng: 10.643 },
  { name: "Mageroklien", lat: 59.155, lng: 10.433 },
  { name: "Maloy", lat: 61.934, lng: 5.116 },
  { name: "Mandal", lat: 58.019, lng: 7.468 },
  { name: "Mehamn", lat: 71.038, lng: 27.844 },
  { name: "Mekjarvik", lat: 59.024, lng: 5.615 },
  { name: "Mjolstadneset", lat: 62.323, lng: 5.668 },
  { name: "Mo I Rana", lat: 66.278, lng: 13.877 },
  { name: "Molde", lat: 62.737, lng: 7.179 },
  { name: "Mongstad", lat: 60.805, lng: 5.012 },
  { name: "Moss", lat: 59.436, lng: 10.654 },
  { name: "Nappsvagen", lat: 68.131, lng: 13.433 },
  { name: "Narvik", lat: 68.423, lng: 17.408 },
  { name: "Ny-Alesund", lat: 78.928, lng: 11.937 },
  { name: "Oksenoya", lat: 59.898, lng: 10.598 },
  { name: "Oksfjord", lat: 70.241, lng: 22.347 },
  { name: "Olen", lat: 59.611, lng: 5.781 },
  { name: "Ornes", lat: 66.868, lng: 13.709 },
  { name: "Oslo", lat: 59.899, lng: 10.725 },
  { name: "Porsgrunn", lat: 59.136, lng: 9.631 },
  { name: "Rafnes", lat: 59.093, lng: 9.603 },
  { name: "Ramber", lat: 68.087, lng: 13.226 },
  { name: "Raudeberg", lat: 61.977, lng: 5.138 },
  { name: "Rekefjord", lat: 58.334, lng: 6.26 },
  { name: "Roervik", lat: 64.863, lng: 11.242 },
  { name: "Rypefjord", lat: 70.639, lng: 23.66 },
  { name: "Sandefjord", lat: 59.119, lng: 10.231 },
  { name: "Sandnes", lat: 58.858, lng: 5.748 },
  { name: "Sandnessjoen", lat: 66.022, lng: 12.634 },
  { name: "Sandviksberget", lat: 64.322, lng: 10.45 },
  { name: "Sarpsborg", lat: 59.269, lng: 11.084 },
  { name: "Skavenik", lat: 59.734, lng: 5.934 },
  { name: "Skjebergkilens", lat: 59.179, lng: 11.159 },
  { name: "Skogn", lat: 63.711, lng: 11.155 },
  { name: "Skudeneshavn", lat: 59.146, lng: 5.263 },
  { name: "Slagen", lat: 59.326, lng: 10.513 },
  { name: "Slovag", lat: 60.847, lng: 5.083 },
  { name: "Sor Arnoy", lat: 67.136, lng: 13.975 },
  { name: "Sortland", lat: 68.704, lng: 15.422 },
  { name: "Sorvar", lat: 70.63, lng: 21.981 },
  { name: "Stavanger", lat: 58.983, lng: 5.732 },
  { name: "Stavern", lat: 58.998, lng: 10.043 },
  { name: "Steinshamn", lat: 62.785, lng: 6.474 },
  { name: "Stokkvaagan", lat: 66.34, lng: 13.003 },
  { name: "Storasund", lat: 59.392, lng: 5.273 },
  { name: "Straumoyvalen", lat: 66.369, lng: 12.593 },
  { name: "Sunde", lat: 59.834, lng: 5.717 },
  { name: "Svolvaer", lat: 68.234, lng: 14.562 },
  { name: "Tananger", lat: 58.928, lng: 5.59 },
  { name: "Tau", lat: 59.063, lng: 5.91 },
  { name: "Tomra", lat: 62.589, lng: 6.92 },
  { name: "Torangsvag", lat: 60.114, lng: 5.159 },
  { name: "Traena", lat: 66.498, lng: 12.094 },
  { name: "Tromso", lat: 69.667, lng: 19.01 },
  { name: "Trondheim", lat: 63.438, lng: 10.388 },
  { name: "Uthaug", lat: 63.73, lng: 9.585 },
  { name: "Vaeroy", lat: 67.657, lng: 12.706 },
  { name: "Vardholmen", lat: 69.14, lng: 15.679 },
  { name: "Vega", lat: 65.679, lng: 12.128 },
];

export { Vessel, StaticStructure, Filterable, getPath, ports };
