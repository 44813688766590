import { DateTime, Duration, Interval } from "luxon";

function validate(date) {
  if (!date) return false;

  let object = DateTime.fromISO(date);

  if (object.isValid && object.year > 1900) return object;

  return false;
}

function format(date, format, noDataSymbol = "-") {
  if (date instanceof DateTime) date = date.toISOString();
  else if (date instanceof Date) date = date.toISOString();

  if (validate(date)) {
    let formatted = DateTime.fromISO(date, {
      setZone: false,
    }).toFormat(format);

    if (format === "Invalid DateTime") return noDataSymbol;

    return formatted;
  }

  return noDataSymbol;
}

function toTime(date) {
  return format(date, "HH:mm");
}

function toDate(date) {
  return format(date, "dd/MM/yyyy");
}

function toDateTime(date, noDataSymbol = "-") {
  return format(date, "dd/MM/yyyy HH:mm", noDataSymbol);
}

function toTimeRange(from, to) {
  const fromFormatted = toTime(from);

  if (fromFormatted === "-") return "-";

  const toFormatted = toTime(to);

  if (toFormatted === "-") return "-";

  return `${fromFormatted} to ${toFormatted}`;
}

function toDateRange(from, to, noDataForToSymbol = "to -") {
  const fromFormatted = toDate(from);

  if (fromFormatted === "-") return "-";

  const toFormatted = toDate(to);

  if (toFormatted === "-") return `${fromFormatted} ${noDataForToSymbol}`;

  return `${fromFormatted} to ${toFormatted}`;
}

function toDateTimeRange(from, to, noDataForToSymbol = "to -") {
  const fromFormatted = toDateTime(from);

  if (fromFormatted === "-") return "-";

  const toFormatted = toDateTime(to);

  if (toFormatted === "-") return `${fromFormatted} ${noDataForToSymbol}`;

  return `${fromFormatted} to ${toFormatted}`;
}

function toHoursDisplay(timeSpan) {
  if (!timeSpan || timeSpan === 0) return "N/A";

  const hoursAndMin = Duration.fromMillis(timeSpan).toFormat("hh:mm").split(":");

  return `${hoursAndMin[0]}h ${hoursAndMin[1]}m`;
}

function toHours(value) {
  if (!value || value === 0) return 0;

  return Duration.fromMillis(value).shiftTo("hours").hours;
}

function toDays(value) {
  if (!value || value === 0) return 0;

  return Duration.fromMillis(value).shiftTo("days").days;
}

function toDigital(value) {
  if (!value || value === 0) return Duration.fromMillis(0).toFormat("hh:mm");

  return Duration.fromMillis(value).toFormat("hh:mm");
}

function toDateObject(value) {
  let date = DateTime.fromFormat(value, "dd/MM/yyyy HH:mm");

  if (date.isValid) {
    return date.toJSDate();
  }

  date = DateTime.fromISO(value);

  if (date.isValid) {
    return date.toJSDate();
  }
}

/* Convert an ECMAScript Date to a Microsoft OADate
 ** Treat all dates as local.
 ** @param {Date} date - Date to convert
 ** @returns {Date}
 */
function dateToOADate(date) {
  if (validate(date)) {
    let dateToConvert = DateTime.fromISO(date);
    let startOfDateToConvert = DateTime.fromISO(date).startOf("day");

    // Get the number of days since the start of the Excel epoch
    let days = Math.round(startOfDateToConvert.diff(DateTime.utc(1899, 12, 30)) / 8.64e7);
    // Get the time since the start of the day
    let partDay = Math.abs(dateToConvert.diff(startOfDateToConvert) % 8.64e7) / 8.64e7;

    return days + partDay;
  }

  return "-";
}

function addMonths(inputDate, months) {
  var date = new Date(inputDate);
  date.setMonth(date.getMonth() + months);
  return date;
}

function* days(interval) {
  if (interval.start) {
    let cursor = interval.start.startOf("day");

    while (cursor < interval.end) {
      yield cursor.toFormat("dd/MM/yyyy");
      cursor = cursor.plus({
        days: 1,
      });
    }
  }
}

function getOverlap(dateFromA, dateToA, dateFromB, dateToB) {
  let dateFromAValid = validate(dateFromA);
  let dateToAValid = validate(dateToA);
  let dateFromBValid = validate(dateFromB);
  let dateToBValid = validate(dateToB);

  if (dateFromAValid && dateToAValid && dateFromBValid && dateToBValid) {
    let overlapAmount = 0;

    // If they overlap
    if (!(dateToAValid < dateFromBValid || dateFromAValid > dateToBValid)) {
      let calcs = [
        dateToAValid.diff(dateFromAValid).get("milliseconds"),
        dateToAValid.diff(dateFromBValid).get("milliseconds"),
        dateToBValid.diff(dateFromBValid).get("milliseconds"),
        dateToBValid.diff(dateFromAValid).get("milliseconds"),
      ];

      // Overlap amount is the least of above calculations
      overlapAmount = Math.min(...calcs);
    }

    return overlapAmount;
  }

  return 0;
}

function intervalToArray(start, end) {
  if (!start && !end) return;

  let interval = Interval.fromDateTimes(DateTime.fromISO(start), DateTime.fromISO(end));

  return Array.from(days(interval));
}

function getTimeInMilliseconds(time, denomination) {
  if (time && time > 0) {
    switch (denomination) {
      case "hours":
        return time * 60 * 60 * 1000;
      case "days":
        return time * 24 * 60 * 60 * 1000;
      default:
        return time;
    }
  }

  return 0;
}

export {
  toTime,
  toDate,
  toDays,
  toDateTime,
  toDateRange,
  toDateTimeRange,
  toDateObject,
  toTimeRange,
  toHours,
  intervalToArray,
  toHoursDisplay,
  addMonths,
  toDigital,
  dateToOADate,
  getTimeInMilliseconds,
  getOverlap,
};
