<template>
    <div>
        <fleet-table></fleet-table>
    </div>
  </template>
<script>
    import fleetTable from '../components/fleet/fleetTable.vue'
    export default {
      name: "fleet",
      components: {fleetTable},
    };
</script>
    

    