import { postRequest, getRequest } from "../../../requests/requests";
import route from "../../router/router";

const state = () => ({
  userId: null,
  userName: null,
  userEmailAddress: null,
  userCompanyName: null,
  userApplications: [],
  userStatus: null,
  navVisible: false,
  userRole: null,
});

const getters = {
  userStatus: (state) => state.userStatus,
  isAuthenticated: (state) => state.userStatus === "logged-in",
  userRole: (state) => state.userRole,
  userEmail: (state) => state.userEmailAddress,
  userName: (state) => state.userName,
};

const actions = {
  async login({ commit }, params) {
    var result = await postRequest("authentication", "login", params);
    if (result) {
      commit("setUserStatus", "logged-in");
      commit("setUserData", result.data);
      route.push({ name: "home" });
    } else {
      commit("setUserStatus", null);
    }
  },
  async logout({ commit }) {
    await getRequest("authentication", "logout");

    commit("setUserStatus", null);
    route.push({ name: "login" });
  },
  async authenticate({ commit }) {
    var result = await getRequest("authentication", "Authenticate");
    if (!result) {
      commit("setUserStatus", null);
      return;
    }
    commit("setUserStatus", result && result.data ? "logged-in" : null);
    if (result && result.data) {
      commit("setUserData", result.data);
    }

    return result.data;
  },
  async tokenLogin({ commit }) {
    commit("setAuthorizing", true);

    var result = await postRequest("sso", `login?token=${route.currentRoute.query.token}`);
    if (result) {
      commit("setUserStatus", "logged-in");
      commit("setUserData", result.data);
      route.push({ name: "home" });
    } else {
      commit("setUserStatus", null);
    }
  },
};

const mutations = {
  setUserStatus(state, val) {
    state.userStatus = val;
  },
  setUserData(state, val) {
    state.userRole = val.role;
    state.userName = val.name;
    state.userEmailAddress = val.emailAddress;
    state.userCompanyName = val.companyName;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
