import Vue from "vue";
import Vuex from "vuex";
import layout from "./modules/layout";
import authentication from "./modules/authentication";
import map from "./modules/map";
import staticStructures from "./modules/staticStructures";
import fleet from "./modules/fleet";
import users from "./modules/users";
import companies from "./modules/companies"
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    layout,
    authentication,
    map,
    staticStructures,
    fleet,
    users,
    companies
  },
});

export default store;
