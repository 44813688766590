<script>
import { Vessel } from "./mapHelpers";
import { toDateTime } from "./../../helpers/dateHelpers";
import MapNotification from "./mapNotification.vue";

export default {
  name: "map-settings",
  components: { MapNotification },
  props: {
    vessel: { type: Vessel, default: null },
    showLegend: Boolean,
    showLabels: Boolean,
    filterToFleet: Boolean,
    showFleetList: Boolean,
  },
  data() {
    return {
      visible: false,
      notificationText: "",
    };
  },
  computed: {
    showLegendLocal: {
      get: function () {
        return this.showLegend;
      },
      set: function (value) {
        this.$emit("update:showLegend", value);
      },
    },
    showLabelsLocal: {
      get: function () {
        return this.showLabels;
      },
      set: function (value) {
        this.$emit("update:showLabels", value);
      },
    },
    filterToFleetLocal: {
      get: function () {
        return this.filterToFleet;
      },
      set: function (value) {
        this.$emit("update:filterToFleet", value);
      },
    },
    showFleetListLocal: {
      get: function () {
        return this.showFleetList;
      },
      set: function (value) {
        this.$emit("update:showFleetList", value);
      },
    },
  },
  methods: {
    onSetHomeViewClick() {
      this.notificationText = "Pan / Zoom and then press 'SET' to set your home view.";
      this.$store.commit("setMapStatus", "setting-home");
    },
    onSetHomeView() {
      this.$emit("onSetHomeView");
    },
    toDateTime: toDateTime,
  },
};
</script>
<template>
  <div>
    <v-menu right top offset-y offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark fab color="white" class="settings-button" v-bind="attrs" v-on="on"
          ><v-icon color="rgba(0,0,0,.87)">mdi-cog-outline</v-icon></v-btn
        >
      </template>
      <v-list>
        <v-dialog width="30%" v-model="visible" @click:outside="visible = false">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-title
                >Display Settings
                <v-icon class="ml-2" style="display: block; float: right">mdi-map-marker-question-outline</v-icon></v-list-item-title
              ></v-list-item
            >
          </template>

          <v-card>
            <v-card-title>Display Settings</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-checkbox v-model="showLegendLocal" label="Include Legend" dense hide-details></v-checkbox>
                  <v-checkbox v-model="showLabelsLocal" label="Include Labels" dense hide-details></v-checkbox>
                  <v-checkbox v-model="filterToFleetLocal" label="Filter to Fleet" dense hide-details></v-checkbox>
                  <v-checkbox v-model="showFleetListLocal" label="Include Fleet List" dense hide-details></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="visible = false">Close</v-btn>

              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-list-item @click="onSetHomeViewClick">
          <v-list-item-title style="text-align: left"
            >Set Home View <v-icon class="ml-2" style="display: block; float: right">mdi-map-check-outline</v-icon></v-list-item-title
          ></v-list-item
        >
      </v-list>
    </v-menu>
    <map-notification :text.sync="notificationText" @onSetHomeView="onSetHomeView"></map-notification>
  </div>
</template>
<style scoped>
.settings-button {
  position: absolute;
  left: 5px;
  bottom: 5px;
  z-index: 9999;
}
</style>
