<template>
  <div class="container">
    <div class="main-content">
      <div class="row">
        <div class="col-lg-12">Signing In...</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sso",
  components: {},
  data: function () {
    return {};
  },
  methods: {},
  computed: {},
  mounted: function () {
    this.$store.dispatch("tokenLogin");
  },
};
</script>
