<template>
  <div>
    <company-table></company-table>
  </div>
</template>
<script>
import CompanyTable from "../components/companies/companyTable.vue";
export default {
  name: "companies",
  components: { CompanyTable },
};
</script>