<script>
export default {
  name: "map-notification",
  props: {
    text: String,
  },
  data() {
    return {};
  },

  methods: {
    onSetHomeView() {
      this.$emit("onSetHomeView");
      this.showSnackBar = false;
    },
  },
  computed: {
    showSnackBar: {
      get() {
        return this.text ? true : false;
      },
      set() {
        this.$emit("update:text", "");
      },
    },
  },
};
</script>
<template>
  <v-snackbar multi-line :timeout="-1" v-model="showSnackBar" top>
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="onSetHomeView"> Set </v-btn>
    </template>
  </v-snackbar>
</template>
<style scoped>
.settings-button {
  position: absolute;
  left: 5px;
  bottom: 5px;
  z-index: 9999;
}
</style>
