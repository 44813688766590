import { postRequest, getRequest, getRequestFromUrl } from "../../../requests/requests";

const state = () => ({
  mapStatus: "",
});

const getters = {
  mapStatus: (state) => state.mapStatus,
};

const actions = {
  async setHomeView(state, params) {
    await postRequest("user", "setHomeView", params);
  },
  async getUserSettings() {
    return await getRequest("user", "getSettings");
  },
  async getAisData() {
    return await getRequestFromUrl("https://aisdatafunctions.azurewebsites.net/api/GetLatestData");
  },
  async getSnailTrail(state, params) {
    return await getRequestFromUrl(`https://aisdatafunctions.azurewebsites.net/api/GetByVessel?imo=${params.imo}&&duration=${params.duration}`);
  },
  async setUserSettings(state, params) {
    return await postRequest("user", "setSettings", params);
  },
};

const mutations = {
  setMapStatus(state, val) {
    state.mapStatus = val;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
