<script>
import dataTable from "../common/dataTable.vue";
export default {
  components: { dataTable },
  name: "static-structure-table",
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Latitude", value: "latitude", sortable: false },
        { text: "Longitude", value: "longitude", sortable: false },
      ],
      defaultObject: {
        id: null,
        name: null,
        latitude: null,
        longitude: null,
        description: null,
        companyId: null,
      },
    };
  },
};
</script>
<template>
  <data-table
    title="Static Structures"
    objectName="Static Structure"
    :headers="headers"
    getMethod="getStaticStructures"
    updateMethod="updateStaticStructure"
    deleteMethod="deleteStaticStructure"
    :editable="true"
    :defaultObject="defaultObject"
  >
    <template v-slot:editForm="{ item }">
      <v-text-field
        class="mb-2"
        outlined
        hide-details="auto"
        v-model="item.name"
        dense
        label="Name"
      ></v-text-field>
      <v-text-field
        class="mb-2"
        outlined
        type="number"
        hide-details="auto"
        v-model="item.latitude"
        dense
        label="Latitude"
      ></v-text-field>
      <v-text-field
        class="mb-2"
        outlined
        type="number"
        hide-details="auto"
        v-model="item.longitude"
        dense
        label="Longitude"
      ></v-text-field>
      <v-textarea
        outlined
        hide-details="auto"
        v-model="item.description"
        dense
        label="Description"
      >
      </v-textarea>
    </template>
  </data-table>
</template>
