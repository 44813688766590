<script>
import mapContainer from '../components/map/mapContainer.vue'

export default {
  name: "home",
  components: { mapContainer }
};
</script>

<template>
  <map-container></map-container>
</template>
