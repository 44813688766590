<template>
  <v-row class="search-wrapper">
    <v-col>
      <v-autocomplete
        outlined
        rounded
        v-model="selected"
        class="search-bar"
        :items="filterable"
        item-text="name"
        :search-input.sync="filter"
        return-object
        placeholder="Search for vessel's, structures and ports..."
        @change="onSearchClick"
        ><template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
            <v-list-item-subtitle>{{
              item.getPositionDisplay()
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon v-if="item.type == 'structure'">mdi-factory</v-icon>
            <v-icon v-else-if="item.type == 'vessel'">mdi-ferry</v-icon>
            <v-icon v-else>mdi-map-marker</v-icon>
          </v-list-item-action>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "map-search-bar",
  props: {
    filterable: { type: Array, default: () => [] },
  },
  data() {
    return {
      filter: "",
      selected: null,
    };
  },
  methods: {
    onSearchClick(item) {
      this.$emit("selected", item);
      this.$nextTick(() => {
        this.selected = null;
        this.filter = "";
      });
    },
  },
};
</script>

<style scoped>
.search-wrapper {
  z-index: 9999;
  position: absolute;
  top: 5px;
  width: 100%;
}
.search-bar {
  max-width: 400px;
  width: 20%;
  margin: auto;
}
</style>
