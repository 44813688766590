import Vue from "vue";
import Router from "vue-router";
import Account from "../../views/Account.vue";
import Login from "../../views/Login.vue";
import Home from "../../views/Home.vue";
import Fleet from "../../views/Fleet.vue";
import StaticStructures from "../../views/StaticStructures.vue";
import Companies from "../../views/Companies.vue";
import Users from "../../views/Users.vue";
import SSO from "../../views/SSO.vue";
import store from "../store";
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/home",
      alias: "/",
      name: "home",
      component: Home,
      meta: {
        title: "Home",
      },
    },
    {
      path: "/account",
      name: "account",
      component: Account,
      meta: {
        title: "Account",
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        title: "Seaspotter",
      },
    },
    {
      path: "/sso",
      name: "sso",
      component: SSO,
      meta: {
        title: "Signing in...",
      },
    },
    {
      path: "/fleet",
      name: "fleet",
      component: Fleet,
      meta: {
        title: "Fleet",
      },
    },
    {
      path: "/staticStructures",
      name: "static-structures",
      component: StaticStructures,
      meta: {
        title: "Static Structures",
      },
    },
    {
      path: "/users",
      name: "users",
      component: Users,
      meta: {
        title: "Users",
        roles: ["administrator"],
      },
    },
    {
      path: "/companies",
      name: "companies",
      component: Companies,
      meta: {
        title: "Companies",
        roles: ["administrator"],
      },
    },
    {
      path: "*",
      redirect: "/login",
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  var authenticated = store.getters.isAuthenticated == true ? true : await store.dispatch("authenticate");



  if (to.name == "login" && to.query.sso) {
    window.location.href = `${process.env.VUE_APP_SEAHUB_URL}?redirect=${process.env.VUE_APP_URL}/sso`;
  }

  if (to.name == "sso") {
    if (authenticated) {
      next("home");
    } else {
      next();
    }
    return;
  }


  if (to.logout) {
    store.dispatch("logout");
    next({ name: "login" });
  }

  if (!authenticated) {
    if (to.name == "login") {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    if (to.name == "login") {
      next({ name: "home" });
    } else {
      var role = store.getters.userRole;
      if (to.meta.roles && to.meta.roles.indexOf(role) == -1) {
        next({ name: "home" });
      } else {
        next();
      }
    }
  }
});

export default router;
