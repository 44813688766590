<script>
export default {
  name: "spinner",
  props: {
    text: { type: String, default: "" },
  },
  data() {
    return {};
  },
};
</script>
<template>
  <div id="wrapper">
    <div class="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <span class="text" v-if="text">{{ text }}</span>
  </div>
</template>
<style scoped>
#wrapper {
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 9999;
  display: table-cell;
  vertical-align: middle;
}
.text {
  text-align: center;
  width: 100%;
  height: 80px;
  position: absolute;
  top: 50%;
  font-size: 1.4rem;
  left: 50%;
  text-shadow: #fff;
  transform: translate(-50%, calc(-50% + 70px));
}
.lds-facebook {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
</style>
