<script>
import mapFleetVessel from "./mapFleetVessel.vue";
export default {
  components: { mapFleetVessel },
  name: "map-fleet",
  props: {
    fleet: { type: Array, default: () => [] },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<template>
  <v-card class="fleet-wrapper">
    <v-card-title> My Fleet </v-card-title>
    <v-card-text style="max-height: 300px; overflow-y: auto">
      <v-row>
        <v-col cols="12" class="pa-0" v-for="(vessel, index) in fleet" :key="`fleet-${index}`">
          <map-fleet-vessel @click="$emit('click', vessel)" :vessel="vessel"></map-fleet-vessel>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style scoped>
.fleet-wrapper {
  max-width: 300px;
  position: absolute;
  right: 5px;
  z-index: 9999;
  bottom: 5px;
}
.legend-icon {
  margin-top: 5px;
}
</style>
