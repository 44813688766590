<template>
  <v-card elevation="2" class="ma-3">
    <v-card-title>
      <span class="mr-2 text-h6"
        >{{ title }}
        <v-btn
          title="Click to Add"
          v-if="editable"
          icon
          color="primary"
          dark
          class=""
          @click="onAddNewItem"
          ><v-icon>mdi-plus</v-icon>
        </v-btn></span
      >
    </v-card-title>
    <v-card-text>
      <v-data-table
        @click:row="onEditItem"
        :items="items"
        :headers="headers"
        :server-items-length="serverItemsLength"
        :options.sync="options"
        :footer-props="footerProps"
      >
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="dialog" width="350px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            `${isEdit ? "Edit" : "Add"} ${objectName}`
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation v-if="activeItem">
            <slot name="editForm" :item="activeItem"></slot>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="onCloseDialog()">Cancel</v-btn>
          <v-btn v-if="isEdit" class="error" @click="onDeleteItem()"
            >Delete</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" @click="onSaveItem()">
            {{ isEdit ? `Save` : `Add` }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  name: "data-table",
  props: {
    title: String,
    objectName: String,
    headers: Array,
    getMethod: String,
    deleteMethod: String,
    updateMethod: String,
    footerProps: {
      type: Object,
      default: () => {
        return { "items-per-page-options": [10, 20, 30, 40, 50, 100] };
      },
    },
    editable: { type: Boolean, default: false },
    defaultObject: { type: Object, default: null },
  },
  data() {
    return {
      items: [],
      activeItem: null,
      serverItemsLength: null,
      dialog: false,
      options: { page: 1, itemsPerPage: 10 },
    };
  },
  methods: {
    async getItems() {
      var response = await this.$store.dispatch(this.getMethod);
      this.items = response.data;
      this.serverItemsLength = response.total;
    },
    async updateItem() {
      await this.$store.dispatch(this.updateMethod, this.activeItem);
      await this.onCloseDialog();
    },
    onEditItem(item) {
      if (!this.editable) return;
      this.activeItem = Object.assign({}, item);
      this.dialog = true;
    },
    onAddNewItem() {
      this.onEditItem(this.defaultObject);
    },
    onCloseDialog() {
      this.dialog = false;
      this.activeItem = null;
      this.getItems();
    },
    async onDeleteItem() {
      await this.$store.dispatch(this.deleteMethod, { id: this.activeItem.id });
      await this.onCloseDialog();
    },
    async onSaveItem() {
      await this.updateItem();
    },
  },
  async mounted() {
    await this.getItems();
  },
  computed: {
    isEdit() {
      return this.activeItem && this.activeItem.id;
    },
  },
};
</script>
    
