<template>
  <div>
    <user-table></user-table>
  </div>
</template>
<script>
import UserTable from "../components/users/userTable.vue";
export default {
  name: "users",
  components: { UserTable },
  methods: {
    async onMount() {
      var companies = await this.$store.dispatch("getCompaniesDataSource");
      this.$store.commit("setCompaniesDataSource", companies);
    },
  },
  async mounted() {
    await this.onMount();
  },
};
</script>