import { postRequest, getRequest } from "../../../requests/requests";

const state = () => ({

});

const getters = {

};

const actions = {
    async getUsers(state, params) {
        var response = await getRequest('User', 'Get', params);
        return response.data;
    },
    async updateUser(state, params) {
        return await postRequest('User', 'AddOrUpdate', params);
    },
    async deleteUser(state, params) {
        return await postRequest('User', `Delete/${params.id}`);
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
};