<script>
import dataTable from "../common/dataTable.vue";
export default {
  components: { dataTable },
  name: "fleet-table",
  data() {
    return {
      headers: [
        { text: "Name", value: "vesselName", sortable: false },
        { text: "IMO", value: "imo", sortable: false },
        { text: "MMSI", value: "mmsi", sortable: false },
      ],
      defaultObject: {
        id: null,
        vesselName: null,
        imo: null,
        mmsi: null,
        companyId: null,
      },
    };
  },
};
</script>
<template>
  <data-table
    title="Fleet"
    objectName="Vessel"
    :headers="headers"
    getMethod="getVessels"
    updateMethod="updateVessel"
    deleteMethod="deleteVessel"
    :editable="true"
    :defaultObject="defaultObject"
  >
    <template v-slot:editForm="{ item }">
      <v-text-field class="mb-2" outlined hide-details="auto" v-model="item.vesselName" dense label="Name"></v-text-field>
      <v-text-field class="mb-2" outlined hide-details="auto" v-model="item.imo" dense label="IMO"></v-text-field>
      <v-text-field outlined hide-details="auto" v-model="item.mmsi" dense label="MMSI"></v-text-field>
    </template>
  </data-table>
</template>
