<template>
  <v-container style="position: relative" class="login-bg fill-height fill-width ma-0 pa-0" align="center" justify="center">
    <div id="map"></div>
    <spinner v-if="loadingData" text="Getting map data..."></spinner>
    <vessel-information-card
      class="info-card"
      v-if="currentObject != null"
      :item="currentObject"
      :isVessel="currentObject.mmsi ? true : false"
      :trailDuration.sync="trailDuration"
    ></vessel-information-card>
    <map-settings
      v-if="userSettings"
      :showLegend.sync="userSettings.showLegend"
      :showLabels.sync="userSettings.showLabels"
      :filterToFleet.sync="userSettings.filterToFleet"
      :showFleetList.sync="userSettings.showFleetList"
      @onSetHomeView="setHomeView"
    ></map-settings>
    <map-legend
      class="legend"
      v-if="userSettings && userSettings.showLegend"
      :ahts.sync="objectVisibility.ahts"
      :psv.sync="objectVisibility.psv"
      :jackUp.sync="objectVisibility.jackUp"
      :other.sync="objectVisibility.other"
      :structure.sync="objectVisibility.structure"
      v-on:toggle="toggleMarkersByType"
    ></map-legend>
    <map-fleet
      @click="onFleetVesselClick"
      v-if="userSettings && userSettings.showFleetList && fleetVessels.length > 0"
      :fleet="fleetVessels"
    ></map-fleet>
    <map-search-bar v-if="filterable.length && !isSettingHomeView" :filterable="filterable" @selected="onSearchItemSelected"></map-search-bar>
  </v-container>
</template>

<script>
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import L from "leaflet";
import "leaflet.markercluster";
import "leaflet-marker-rotation";
import { antPath } from "leaflet-ant-path";
import { Vessel, StaticStructure, getPath, Filterable, ports } from "./mapHelpers";
import spinner from "../common/spinner.vue";
import vesselInformationCard from "./vesselInformationCard.vue";
import MapSettings from "./mapSettings.vue";
import mapLegend from "./mapLegend.vue";
import mapFleet from "./mapFleet.vue";
import mapSearchBar from "./mapSearchBar.vue";

export default {
  name: "map-container",
  components: {
    spinner,
    vesselInformationCard,
    MapSettings,
    mapLegend,
    mapFleet,
    mapSearchBar,
  },
  props: {
    maxClusterLevel: { type: Number, default: 9 },
  },
  data() {
    return {
      vesselMarkers: [],
      structureMarkers: [],
      vesselData: {},
      structureData: {},
      currentObject: null,
      activeTrail: null,
      map: null,
      typesToIgnore: [30, 35, 36, 37, 60],
      clusteredMarkers: null,
      loadingData: true,
      userSettings: null,
      messageText: null,
      objectVisibility: {
        ahts: true,
        psv: true,
        jackUp: true,
        other: false,
        structure: true,
      },
      validTypes: ["AHTS", "PSV", "Structure", "Other", "JackUp"],
      fleet: [],
      vesselFilter: {
        types: ["AHTS", "PSV", "Structure", "JackUp"],
        fleet: false,
      },
      trailDuration: 24,
      target: {
        icon: null,
        marker: null,
      },
      ports: ports,
    };
  },
  methods: {
    async getUserSettings() {
      var result = await this.$store.dispatch("getUserSettings");
      return result.data;
    },
    async init() {
      await this.initializeMap();
      await this.initializeVesselData();
      await this.getFleet();
      this.initializeStaticStructureData();
      this.initializeClusteredMarkers();
      this.filterVessels();
    },
    async initializeMap() {
      this.userSettings = await this.getUserSettings();
      this.map = L.map("map", {
        zoomControl: false,
        preferCanvas: true,
      }).setView([this.userSettings.defaultLatitude, this.userSettings.defaultLongitude], this.userSettings.defaultZoom);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}", {
        foo: "bar",
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      }).addTo(this.map);

      this.map.on("click", () => {
        this.unselectActive();
        this.filterVessels();
      });
    },
    async GetAisData() {
      return await this.$store.dispatch("getAisData");
    },
    unselectActive() {
      this.removeTrail();
      this.activeTrail = null;
      this.currentObject = null;
      this.unHighlightActive();
    },
    async initializeVesselData() {
      var aisData = await this.GetAisData();

      var ignore = this.typesToIgnore;

      for (var i = 0; i < aisData.data.length; i++) {
        var thisItem = aisData.data[i];
        if (ignore.indexOf(thisItem.shipType) > -1) {
          continue;
        }
        this.defineVessel(aisData.data[i]);
      }
    },
    async getFleet() {
      var result = await this.$store.dispatch("getVessels");
      this.fleet = result.data;
    },
    async initializeStaticStructureData() {
      var staticStructures = await this.$store.dispatch("getStaticStructures", {});

      for (var i = 0; i < staticStructures.data.length; i++) {
        this.defineStaticStructure(staticStructures.data[i]);
      }
      this.addStructuresToMap();
    },
    initializeClusteredMarkers() {
      this.clusteredMarkers = L.markerClusterGroup({
        animateAddingMarkers: false,
        disableClusteringAtZoom: this.maxClusterLevel,
        spiderfyOnMaxZoom: false,
      });

      this.map.addLayer(this.clusteredMarkers);
      this.loadingData = false;
    },
    addStructuresToMap() {
      for (var i = 0; i < this.structureMarkers.length; i++) {
        this.structureMarkers[i].addTo(this.map);
      }
    },
    removeStructuresFromMap() {
      for (var i = 0; i < this.structureMarkers.length; i++) {
        this.map.removeLayer(this.structureMarkers[i]);
      }
    },
    defineVessel(item) {
      var vessel = new Vessel(
        item.name,
        item.latitude,
        item.longitude,
        this.userSettings.showLabels,
        item.imo,
        item.mmsi,
        item.heading,
        item.destination,
        item.speed,
        item.vesselType && this.validTypes.indexOf(item.vesselType) > -1 ? item.vesselType : "Other",
        item.updated
      );

      this.vesselData[vessel.imo] = vessel;
      var marker = vessel.createMarker(L, this.onVesselMarkerClick);
      this.vesselMarkers.push(marker);
    },
    defineStaticStructure(item) {
      var structure = new StaticStructure(item.name, item.latitude, item.longitude, this.userSettings.showLabels, item.description);
      this.structureData[item.name] = structure;
      var marker = structure.createMarker(L, this.onStaticStructureMarkerClick);
      this.structureMarkers.push(marker);
    },
    async onVesselMarkerClick(imo) {
      var data = this.vesselData[imo];
      this.currentObject = data;
      this.goToActive();
    },
    async goToActive() {
      var currentZoom = this.getZoom();

      if (currentZoom < this.maxClusterLevel) {
        this.jumpToActive(9);
      } else {
        this.jumpToActive(currentZoom);
      }
      this.highlightActive();
      await this.createSnailTrail(this.currentObject.imo);
    },
    async onStaticStructureMarkerClick(e) {
      var data = this.structureData[e.target.options.name];
      this.currentObject = data;
      this.goToActive();
    },
    async createSnailTrail(imo) {
      var result = await this.$store.dispatch("getSnailTrail", {
        imo: imo,
        duration: this.trailDuration,
      });
      this.removeTrail();

      this.activeTrail = this.getTrailMarker(result.data);

      this.activeTrail.addTo(this.map);
    },
    highlightActive() {
      this.unHighlightActive();
      var position = this.currentObject.getPosition();
      this.target.marker = new L.Marker(position, {
        icon: L.divIcon({
          iconAnchor: [25, 25],
          iconSize: [50, 50],
          className: "pulsating-circle",
        }),
      });
      this.target.marker.addTo(this.map);
    },
    unHighlightActive() {
      if (this.target.marker) {
        this.map.removeLayer(this.target.marker);
      }
    },
    removeTrail() {
      if (this.activeTrail) {
        this.map.removeLayer(this.activeTrail);
      }
    },
    getZoom() {
      return this.map.getZoom();
    },
    getCenter() {
      return this.map.getCenter();
    },
    jumpToActive(zoom) {
      this.map.flyTo(this.currentObject.getPosition(), zoom, { duration: 0.5 });
    },
    jumpToPosition(lat, lng, zoom) {
      this.map.flyTo([lat, lng], zoom, { duration: 0.5 });
    },
    getTrailMarker(points) {
      var coordinates = this.getPath(points);
      const options = {
        use: L.polyline,
        delay: 400,
        dashArray: [1, 100],
        weight: 5,
        color: "#F70000",
        pulseColor: "#FFFFFF",
      };
      var result = antPath(coordinates, options);
      return result;
    },
    setHomeView() {
      var currentZoom = this.getZoom();
      var center = this.getCenter();
      var data = {
        defaultLatitude: center.lat,
        defaultLongitude: center.lng,
        defaultZoom: currentZoom,
      };
      this.$store.dispatch("setHomeView", data);
    },
    filterVessels() {
      var vesselImos = this.getFilteredVesselImos();
      var allVessels = this.vesselMarkers;
      for (var i = 0; i < allVessels.length; i++) {
        var marker = allVessels[i];
        if (vesselImos.indexOf(marker.options.imo) > -1) {
          this.clusteredMarkers.addLayer(marker);
        } else {
          this.clusteredMarkers.removeLayer(marker);
        }
      }
    },
    getFilteredVesselImos() {
      var myFleetOnly = this.vesselFilter.fleet;
      var includedTypes = this.vesselFilter.types;
      var allVessels = this.vesselMarkers;
      var myFleetImos = this.fleet.map((x) => Number(x.imo));
      var result = [];
      for (var i = 0; i < allVessels.length; i++) {
        var marker = allVessels[i];
        var vesselData = this.vesselData[marker.options.imo];
        if (includedTypes.indexOf(vesselData.type) > -1 && (!myFleetOnly || (myFleetOnly && myFleetImos.indexOf(marker.options.imo) > -1))) {
          result.push(marker.options.imo);
        }
      }
      return result;
    },
    toggleMarkersByType(data) {
      if (data.type == "Structure") {
        if (data.show) {
          this.showStructures();
        } else {
          this.hideStructures();
        }
      } else if (data.show) {
        this.vesselFilter.types.push(data.type);
      } else {
        this.vesselFilter.types = this.vesselFilter.types.filter((x) => x != data.type);
      }
    },
    getAllMarkers() {
      var vessels = this.vesselMarkers;
      var structures = this.structureMarkers;
      return vessels.concat(structures);
    },
    toggleToolTips() {
      var settings = this.userSettings;
      var allMarkers = this.getAllMarkers();
      if (!settings) return;

      for (var i = 0; i < allMarkers.length; i++) {
        var marker = allMarkers[i];
        var tooltip = marker.getTooltip();
        if (tooltip) {
          marker.unbindTooltip().bindTooltip(tooltip, {
            permanent: settings.showLabels,
          });
        }
      }
    },
    hideVesselsByType(type) {
      for (var i = 0; i < this.vesselMarkers.length; i++) {
        var marker = this.vesselMarkers[i];
        var vesselData = this.vesselData[marker.options.imo];
        if (vesselData.type == type) {
          this.clusteredMarkers.removeLayer(marker);
        }
      }
    },
    showVesselsByType(type) {
      for (var i = 0; i < this.vesselMarkers.length; i++) {
        var marker = this.vesselMarkers[i];
        var vesselData = this.vesselData[marker.options.imo];
        if (vesselData.type == type) {
          this.clusteredMarkers.addLayer(marker);
        }
      }
    },
    hideStructures() {
      var map = this.map;
      for (var i = 0; i < this.structureMarkers.length; i++) {
        map.removeLayer(this.structureMarkers[i]);
      }
    },
    showStructures() {
      var map = this.map;
      for (var i = 0; i < this.structureMarkers.length; i++) {
        map.addLayer(this.structureMarkers[i]);
      }
    },
    onFleetVesselClick(vessel) {
      var data = this.vesselData[vessel.imo];
      this.currentObject = data;
      this.goToActive();
    },
    onSearchItemSelected(item) {
      if (item.type == "vessel") {
        this.onVesselMarkerClick(item.imo);
      } else {
        this.jumpToPosition(item.lat, item.lng, item.type == "port" ? 13 : this.getZoom());
      }
    },
    getPath: getPath,
  },
  computed: {
    mapStatus: {
      get: function () {
        return this.$store.getters.mapStatus;
      },
      set: function (value) {
        this.$store.commit("setMapStatus", value);
      },
    },
    isSettingHomeView() {
      return this.mapStatus == "setting-home";
    },
    fleetVessels() {
      var myFleetImos = this.fleet.map((x) => x.imo);
      var vesselData = this.vesselData;
      var visibleFleet = myFleetImos.filter((x) => vesselData[x] != undefined);
      return visibleFleet.map((x) => vesselData[x]);
    },
    filterable() {
      var result = [];
      var vesselData = this.vesselData;
      var structureData = this.structureData;
      var allVessels = this.vesselMarkers;
      var allStructures = this.structureMarkers;
      var filteredVessels = this.getFilteredVesselImos();
      for (var v = 0; v < allVessels.length; v++) {
        if (filteredVessels.indexOf(allVessels[v].options.imo) > -1) {
          result.push(
            new Filterable(
              vesselData[allVessels[v].options.imo].name,
              vesselData[allVessels[v].options.imo].lat,
              vesselData[allVessels[v].options.imo].lng,
              "vessel",
              allVessels[v].options.imo
            )
          );
        }
      }
      for (var s = 0; s < allStructures.length; s++) {
        result.push(
          new Filterable(
            structureData[allStructures[s].options.name].name,
            structureData[allStructures[s].options.name].lat,
            structureData[allStructures[s].options.name].lng,
            "structure"
          )
        );
      }
      for (var p = 0; p < this.ports.length; p++) {
        result.push(new Filterable(this.ports[p].name, this.ports[p].lat, this.ports[p].lng, "port"));
      }
      return result;
    },
  },
  watch: {
    userSettings: {
      deep: true,
      handler(val, oldVal) {
        if (!oldVal) return;
        this.$store.dispatch("setUserSettings", val);
      },
    },
    "userSettings.showLabels"() {
      this.toggleToolTips();
    },
    "userSettings.filterToFleet"(val) {
      this.vesselFilter.fleet = val;
    },
    vesselFilter: {
      deep: true,
      handler() {
        this.filterVessels();
      },
    },
    trailDuration(val, oldVal) {
      if (oldVal && val != oldVal && this.currentObject && this.currentObject.imo) {
        this.createSnailTrail(this.currentObject.imo);
      }
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style type="scss">
#map {
  width: 100vw;
  height: 100vh;
}
.fill-width {
  max-width: 100%;
}
.info-card {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 99999;
}
.legend {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 99999;
}
</style>
