<script>
export default {
  name: "corporate-logo",
};
</script>

<template>
  <div
    class="group-logo-wrapper"
    title="Courtesy of Seabrokers - The shipbroker with a difference"
    @click="window.open(`https://www.seabrokers.co.uk/shipping/`, `_blank`)"
  >
    <v-img max-width="250" class="mx-auto" :src="require('@/assets/images/seabrokers-group-logo.png')" />
  </div>
</template>
