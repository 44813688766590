<template>
  <v-navigation-drawer app v-model="navVisible" permanent :mini-variant="mini" class="text-center">
    <v-list-item>
      <v-icon @click="toggleMini">mdi-menu</v-icon>
    </v-list-item>
    <v-list-item v-for="(item, index) in includedNavigationItems" link :key="`nav-${index}`" @click="onNavClick(item.link)">
      <v-icon small dense>{{ item.icon }}</v-icon>
      <v-list-item-title>{{ item.text }}</v-list-item-title>
    </v-list-item>
    <v-list-item link :key="`nav-logout`" @click="onLogoutClick()">
      <v-icon small dense>mdi-logout</v-icon>
      <v-list-item-title>Sign Out</v-list-item-title>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "side-navigation",

  props: {
    drawer: { type: Boolean, default: false },
  },
  data: () => ({
    navigationItems: [
      { text: "Map", icon: "mdi-map-marker", link: "/Map" },
      { text: "Fleet", icon: "mdi-ferry", link: "/Fleet" },
      { text: "Structures", icon: "mdi-factory", link: "/StaticStructures" },
      { text: "Users", icon: "mdi-account-group", link: "/Users", admin: true },
      {
        text: "Companies",
        icon: "mdi-domain",
        link: "/Companies",
        admin: true,
      },
    ],
    mini: true,
  }),
  methods: {
    toggleMini() {
      this.mini = !this.mini;
    },
    onNavClick(link) {
      this.$router.push({ path: link });
    },
    onLogoutClick() {
      this.$store.dispatch("logout");
    },
  },
  computed: {
    includedNavigationItems() {
      if (this.isAdministrator) {
        return this.navigationItems;
      }
      return this.navigationItems.filter((x) => !x.admin);
    },
    navVisible: {
      get() {
        return this.$store.state.layout.navVisible;
      },
      set(val) {
        this.$store.commit("setNavVisibility", val);
      },
    },
  },
};
</script>
<style></style>
