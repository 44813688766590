<script>
import dataTable from "../common/dataTable.vue";
export default {
  components: { dataTable },
  name: "company-table",
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Type", value: "typeDisplay", sortable: false },
      ],
      defaultObject: {
        id: null,
        name: null,
        type: null,
      },
    };
  },
  computed: {
    companyDataSource() {
      return this.$store.getters.companiesDataSource;
    },
  },
};
</script>
<template>
  <data-table
    title="Companies"
    objectName="Company"
    :headers="headers"
    getMethod="getCompanies"
    updateMethod="updateCompany"
    deleteMethod="deleteCompany"
    :editable="true"
    :defaultObject="defaultObject"
  >
    <template v-slot:editForm="{ item }">
      <v-text-field
        class="mb-2"
        outlined
        hide-details="auto"
        v-model="item.name"
        dense
        label="Name"
      ></v-text-field>
    </template>
  </data-table>
</template>
