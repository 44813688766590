<template>
  <v-row style="position: relative" class="login-bg fill-height ma-0 pa-0" align="center" justify="center">
    <v-img
      height="100%"
      width="100%"
      style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px"
      :src="require('@/assets/images/seaspotter-background.jpg')"
      :lazy-src="require('@/assets/images/seaspotter-background - lowres.jpg')"
    ></v-img>
    <v-col sm="8" md="6" lg="4">
      <v-card class="pa-5 mx-auto" max-width="400px">
        <v-row align="center" justify="center">
          <v-col cols="10" lg="8">
            <v-img contain :src="require('@/assets/images/seaspotter-logo.png')"></v-img>
          </v-col>
        </v-row>

        <v-form ref="form" @submit.prevent="attemptLogin">
          <v-row dense>
            <v-col cols="12">
              <v-text-field dense outlined v-model="email" label="Email Address" :rules="[required, validEmail]" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                :placeholder="' '"
                @click:append="showPassword = !showPassword"
                :rules="[required]"
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn :loading="isLoggingIn" width="100%" color="primary" type="submit">Login</v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <a @click="showResetPassword = true" class="d-inline-block info--text">Forgotten password? Click here to reset</a>
            </v-col>
          </v-row>
          <v-row>
            <v-col> <corporate-logo> </corporate-logo> </v-col>
          </v-row>
        </v-form>
      </v-card>

      <reset-password-modal :visible="showResetPassword" @close="showResetPassword = false"></reset-password-modal>
    </v-col>
  </v-row>
</template>
<script>
import rules from "../../mixins/rules";
import resetPasswordModal from "../login/resetPasswordModal.vue";
import corporateLogo from "../layout/seabrokers/corporateLogo";
export default {
  mixins: [rules],
  components: {
    resetPasswordModal,
    corporateLogo,
  },
  data: () => {
    return {
      email: "",
      password: "",
      showPassword: false,
      rememberMe: true,
      showResetPassword: false,
    };
  },
  methods: {
    attemptLogin() {
      this.userStatus = "logging-in";
      var emailAddress = this.email;
      var password = this.password;
      if (this.$refs.form.validate()) {
        this.$store.dispatch("login", { emailAddress, password });
      }
    },
    keyDownHandler(e) {
      if (e.keyCode == 13) {
        e.preventDefault();
        this.attemptLogin();
      }
    },
  },
  computed: {
    isLoggingIn() {
      return this.userStatus == "logging-in";
    },
    userStatus: {
      get() {
        return this.$store.state.userStatus;
      },
      set(val) {
        this.$store.commit("setUserStatus", val);
      },
    },
  },
  created() {
    window.addEventListener("keydown", this.keyDownHandler);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
};
</script>

<style scoped></style>
