import { postRequest, getRequest } from "../../../requests/requests";

const state = () => ({

});

const getters = {

};

const actions = {
    async getStaticStructures(state, params) {
        var response = await getRequest('staticStructure', 'Get', params);
        return response.data;
    },
    async updateStaticStructure(state, params) {
        return await postRequest('staticStructure', 'AddOrUpdate', params);
    },
    async deleteStaticStructure(state, params) {
        return await postRequest('staticStructure', `Delete/${params.id}`);
    },
};

const mutations = {
    setMapStatus(state, val) {
        state.mapStatus = val;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};