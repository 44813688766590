<template>
  <div id="app">
    <v-app>
      <side-navigation v-if="isAuthenticated"></side-navigation>
      <v-main :class="navExpanded ? 'offset-left' : ''">
        <router-view :key="$route.fullPath" />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import sideNavigation from "./components/layout/sideNavigation.vue";
export default {
  name: "App",
  components: {
    sideNavigation,
  },
  computed: {
    navExpanded() {
      return this.$store.getters.navExpanded;
    },
  },
  mounted() {},
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Seaspotter";
      },
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.offset-left {
  margin-left: 56px;
}
</style>
