import { postRequest, getRequest, getRequestFromUrl } from "../requests/requests";

export default {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {
        };
      },

      computed: {
        isAuthenticated() {
          return options.store.getters.isAuthenticated;
        },
        userRole() {
          return options.store.getters.userRole;
        },
        userName() {
          return options.store.getters.userName;
        },
        isAdministrator() {
          return this.userRole == "administrator";
        }
      },
      methods: {
        async get(controller, method, params) {
          return await getRequest(controller, method, params);
        },
        async post(controller, method, params) {
          return await postRequest(controller, method, params);
        },
        async getFromUrl(url) {
          return await getRequestFromUrl(url);
        },
      },
    });
  },
};
