<template>
  <div>
    <static-structure-table></static-structure-table>
  </div>
</template>
<script>
import StaticStructureTable from "../components/staticStructures/staticStructureTable.vue";
export default {
  name: "static-structures",
  components: { StaticStructureTable },
};
</script>
