import { postRequest, getRequest } from "../../../requests/requests";

const state = () => ({

});

const getters = {

};

const actions = {
    async getVessels(state, params) {
        var response = await getRequest('vessel', 'Get', params);
        return response.data
    },
    async updateVessel(state, params) {
        return await postRequest('vessel', 'AddOrUpdate', params);
    },
    async deleteVessel(state, params) {
        return await postRequest('vessel', `Delete/${params.id}`);
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
};