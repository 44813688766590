<script>
export default {
  name: "map-legend",
  props: {
    ahts: Boolean,
    psv: Boolean,
    jackUp: Boolean,
    other: Boolean,
    structure: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ahtsLocal: {
      get: function () {
        return this.ahts;
      },
      set: function (value) {
        this.$emit("update:ahts", value);
        this.$emit("toggle", { show: value, type: "AHTS" });
      },
    },
    psvLocal: {
      get: function () {
        return this.psv;
      },
      set: function (value) {
        this.$emit("update:psv", value);
        this.$emit("toggle", { show: value, type: "PSV" });
      },
    },
    jackUpLocal: {
      get: function () {
        return this.jackUp;
      },
      set: function (value) {
        this.$emit("update:jackUp", value);
        this.$emit("toggle", { show: value, type: "JackUp" });
      },
    },
    otherLocal: {
      get: function () {
        return this.other;
      },
      set: function (value) {
        this.$emit("update:other", value);
        this.$emit("toggle", { show: value, type: "Other" });
      },
    },
    structureLocal: {
      get: function () {
        return this.structure;
      },
      set: function (value) {
        this.$emit("update:structure", value);
        this.$emit("toggle", { show: value, type: "Structure" });
      },
    },
  },
  methods: {},
};
</script>
<template>
  <v-card class="legend-wrapper">
    <v-card-text>
      <v-row>
        <v-col cols="6" class="py-0">
          <v-checkbox v-model="psvLocal" label="PSV" dense hide-details>
            <template v-slot:prepend>
              <div :class="`arrow PSV legend-icon`"></div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-checkbox v-model="ahtsLocal" label="AHTS" dense hide-details>
            <template v-slot:prepend>
              <div :class="`arrow AHTS legend-icon`"></div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-checkbox v-model="jackUpLocal" label="Jack Up" dense hide-details>
            <template v-slot:prepend>
              <div class="jackup-legend"></div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-checkbox v-model="otherLocal" label="Other" dense hide-details>
            <template v-slot:prepend>
              <div :class="`arrow Other legend-icon`"></div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-checkbox
            v-model="structureLocal"
            label="Structure"
            dense
            hide-details
          >
            <template v-slot:prepend>
              <div :class="`structure-legend`"></div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style scoped>
.legend-wrapper {
  max-width: 300px;
}
.settings-button {
  position: absolute;
  left: 5px;
  bottom: 5px;
  z-index: 9999;
}
.legend-icon {
  margin-top: 5px;
}
</style>
